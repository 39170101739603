.card-container {
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
}

.card-container:hover {
    transform: scale(1.05);
}

.profile-image {
    max-width: 300px;
    border-radius: 10px 0 0 10px;
}

.profile-info {
    flex: 1;
    padding: 20px;
}

.profile-name {
    font-size: 24px;
    font-weight: bold;
}

.profile-description {
    margin-top: 10px;
    font-size: 16px;
}

.profile-button {
    width: 10px;
    height: 28px;
    font-size: large;
    margin-top: 20px;
    background-color: #4e2f5d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.profile-button:hover {
    transform: scale(1.05);
    background-color: #8d2e94;
}
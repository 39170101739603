.App {
  background-color: #553f5e;
  display: flex;
  overflow: auto;
  transition: all 0.3s ease;
  min-height: 100%;
}


.content {
  background-color: #ffffff;
  padding: 25px;
  text-align: left;

  margin-left:25%;
  margin-right: 25%;
  min-height : 100vh
  
}

.contentText{
  text-align: left;

}

ul.contentText{
  padding-left: 20px;
}



* {
  margin: 0;
  padding: 0;
}

.horizontal-nav {
  justify-content: center;
  background-color: #ffffff;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-bottom: none; /* Adaugăm un border sub meniu pentru delimitare */
}

.horizontal-nav h1 {
  color: #ccc;
  font-size: 40px; /* Mărimea textului pentru titlu */
}

.horizontal-nav ul {
  justify-content: center;
  list-style: none;
  display: flex;
}

.horizontal-nav li {
  margin-left: 15px;
  margin-right: 15px;
}
.horizontal-nav ul li a {
  font-size: 20px;
  color: black; /* Textul să fie negru */
  text-decoration: none; /* Să nu existe subliniere inițială */
  position: relative; /* Elementul să aibă o poziție relativă pentru a permite deplasarea */
}

/* La hover, aplicăm sublinierea și schimbăm poziția */
.horizontal-nav ul li a:hover {
  color: #8d2e94;
  text-decoration: underline; /* Adăugăm sublinierea la hover */
  top: 5px; /* Deplasăm elementul în jos cu 2 pixeli */
}

/* .horizontal-nav.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  height: 20px; 
  padding: 5px 0; 
  background-color: #333;
} */

        /* Stil pentru lista de proiecte IoT */
.Article {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.Article ul {
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
}

.Article li {
  margin: 20px;
  padding: 20px;
  width: 75%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
}

.Article li:hover {
  transform: translateY(-5px);
}

.Article a {
  display: flex;
  text-decoration: none;
  color: #333;
  flex: 1;
}

.Article img {
  width: 30%;
  height: fit-content;
  border-radius: 5px;
  margin-right: 20px;
}

.Article .info {
  flex: 3;
}

.Article h3 {
  margin: 0 0 10px 0;
  font-size: 20px;
}

.Article p {
  margin: 0;
  font-size: 16px;
  color: #777;
}



.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  width: 100%;
  background-color: rgb(112, 104, 114);
  color: white;
  text-align: center;
}
.footer ul{
  justify-content: center;
  list-style: none;
  display: flex;
 
}
.footer li{
  padding-left: 20px;
  text-decoration: none; /* Elimină sublinierea */
  color: #000; /* Setează culoarea dorită pentru link */
}
.footer a{
  color: #ff9900; /* Culoarea link-ului */
  text-decoration: none; /* Elimină sublinierea */
}

.footer ul.footer-links {
  list-style-type: none; /* Elimină marcatorul de listă */
  padding: 0; /* Elimină spațiile interioare ale listei */
}

/* Stilizarea fiecărui element <li> din lista footer-links */
.footer ul.footer-links li {
  display: inline; /* Aliniază elementele pe aceeași linie */
  margin-right: 20px; /* Spațiere între elemente */
}

/* Stilizarea link-urilor din lista footer-links */
.footer ul.footer-links li a {
  color: #fff; /* Culoarea link-urilor */
  text-decoration: none; /* Elimină sublinierea */
}

/* Stilizarea link-urilor din lista footer-links la hover */
.footer ul.footer-links li a:hover {
  color: #ff9900; /* Culoarea la hover */
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}
.grid-item {
  border: 0px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.grid-item-serious {
  border: 0px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.grid-item-serious:hover {
  transform: scale(1.05); 
}

.grid-item:hover p {
  transform: scale(1.05); 
}

.grid-item img {
  transition: transform 0.3s ease; /* Adaugă tranziție pentru transform */
}

.grid-item:hover img {
  transform: rotate(10deg); /* Rotirea imaginii la hover */
  /* Sau poți folosi translateY pentru mișcare verticală */
  /* transform: translateY(-10px); */
}



@keyframes flamingText {
  0% {
    color: #FF5722; /* Culoare de bază, de exemplu, portocaliu aprins */
    text-shadow: 0 0 10px #FF5722, 0 0 20px #FF5722, 0 0 30px #FF5722; /* Umbră text cu efect de flăcări */
  }
  50% {
    color: #FFC107; /* O altă culoare pentru efectul de pulsare */
    text-shadow: 0 0 20px #FFC107, 0 0 30px #FFC107, 0 0 40px #FFC107; /* Umbră text cu efect de flăcări */
  }
  100% {
    color: #FF5722; /* Revenim la culoarea de bază */
    text-shadow: 0 0 10px #FF5722, 0 0 20px #FF5722, 0 0 30px #FF5722; /* Umbră text cu efect de flăcări */
  }
}

.flaming-text {
  font-size: 20px; /* Dimensiune text */
  font-weight: bold; /* Stil text */
  animation: flamingText 2s infinite; /* Aplicăm animația cu efect de flăcări */
}

@media screen and (max-width: 800px){
  .horizontal-nav li {
    margin-left: 5px;
    margin-right: 5px;
  }
  .horizontal-nav ul li a {
    font-size: 15px;
    color: black; /* Textul să fie negru */
    text-decoration: none; /* Să nu existe subliniere inițială */
    position: relative; /* Elementul să aibă o poziție relativă pentru a permite deplasarea */
  }
  .content{
      /* padding-left:5%;   
      padding-right:5%; */
      margin-left:5%;
      margin-right:5%;
  }
  .flaming-text {
    font-size: 15px; /* Dimensiune text */
    font-weight: bold; /* Stil text */
    animation: flamingText 2s infinite; /* Aplicăm animația cu efect de flăcări */
  }
}

